<template>
  <div class="not-found-container">
    <a class="go-home-text" @click="$router.push({ name: 'Home' })"
      >Ir al inico</a
    >
    <p class="not-found-text">Esta página no existe</p>
    <p class="not-found-text">404</p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.not-found-container {
  height: 100vh;
  text-align: center;
}

.not-found-text {
  position: relative;
  top: 25%;
  font-size: 74px;
}

.go-home-text {
  position: relative;
  top: 25%;
  font-size: 24px;
  color: var(--dark-blue-color);
}

@media screen and (max-width: 768px) {
  .go-home-text {
    top: 5%;
    color: var(--dark-blue-color);
  }

  .not-found-text {
    top: 5%;
  }
}
</style>
